<template>
    <div
        v-if="clientTask"
        class="flex max-w-full flex-1 flex-col overflow-hidden"
    >
        <div class="border-l border-r border-gray-300">
            <div
                class="flex h-16 items-center justify-between gap-2 overflow-hidden px-6"
            >
                <h5
                    class="truncate text-lg font-medium text-gray-700"
                    v-if="clientTask"
                >
                    {{ clientTask.subject }}
                </h5>
                <div v-else />
                <div class="flex items-center justify-center">
                    <base-button
                        v-if="ableToCreateNewChat"
                        class="whitespace-nowrap"
                        variant="primary"
                        icon="line-icons:communication:message-chat-square"
                        size="sm"
                        @click="toggleNewChat"
                    >
                        {{ $t('accountantProjects.newChat') }}
                    </base-button>

                    <span
                        v-if="!showClientTasks"
                        class="ml-2.5 cursor-pointer text-sm font-medium text-primary-700"
                        @click="emit('on-show-client-task')"
                    >
                        {{ $t('accountantProjects.showClientTasks') }}
                    </span>

                    <div
                        v-if="clientTask.sub_tasks.length > 0"
                        class="ml-1 min-w-9"
                    >
                        <base-badge
                            :label="`${totalDoneTasks(clientTask.sub_tasks)}/${
                                clientTask.sub_tasks.length
                            }`"
                            variant="gray"
                            size="sm"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            class="flex h-full flex-col gap-2.5 overflow-y-auto border border-gray-300"
        >
            <accountant-projects-detail-client-tasks-chat-window
                v-if="clientTask"
                :key="clientTask.id"
                :room="clientTask.room"
            />
        </div>
    </div>

    <div
        v-else
        class="flex max-w-full flex-1 flex-col items-center justify-center border-l border-gray-200"
    >
        <template v-if="ableToCreateNewChat">
            <p class="mb-1 text-md font-medium text-gray-900">
                {{ $t('accountantProjects.selectAChat') }}
            </p>
            <p class="text-sm font-normal text-gray-500">
                {{ $t('accountantProjects.clickOnAChatToRead') }}
            </p>
            <base-button
                class="mt-6"
                variant="primary"
                icon="line-icons:general:plus"
                @click="toggleNewChat"
            >
                {{ $t('accountantProjects.startAChat') }}
            </base-button>
        </template>
    </div>

    <accountant-projects-detail-client-tasks-chat-new-chat
        v-if="ableToCreateNewChat"
        :project="project"
        :show="state.showNewChat"
        @slide-close="toggleNewChat"
        @finish-create="onFinishCreateChat"
    />
</template>
<script setup>
import { useClientTask } from '@tenant/modules/accountant/practise-projects/composables/use-client-tasks'
import { totalDoneTasks } from '@tenant/modules/accountant/practise-projects/utils/helper'

const emit = defineEmits(['finish-create', 'on-show-client-task'])

const props = defineProps({
    project: {
        type: Object,
        default: null,
    },
    clientTask: {
        type: Object,
        default: null,
    },
    showClientTasks: {
        type: Boolean,
        default: false,
    },
})

const emitter = useEmitter()
const { newClientTask, updateClientTask } = useClientTask(props.project?.id)

const state = reactive({
    showNewChat: false,
})

const ableToCreateNewChat = computed(() => !!props.project)

onMounted(() => {
    emitter.on('on-new-message', onNewMessage)
})

onUnmounted(() => {
    emitter.off('on-new-message', onNewMessage)
})

const onNewMessage = ({ message, room_id }) => {
    const { clientTask } = props
    if (!clientTask) {
        return
    }

    if (room_id !== clientTask.room.id) {
        return
    }

    updateClientTask({
        ...clientTask,
        room: {
            ...clientTask.room,
            latest_message: message,
        },
    })
}

const toggleNewChat = () => {
    state.showNewChat = !state.showNewChat
}

const onFinishCreateChat = (clientTask) => {
    toggleNewChat()
    newClientTask(clientTask)
    emit('finish-create', clientTask)
}
</script>
